<template>
  <div v-loading="loading">
    <div>
      <swiper class="owl-carousel" :options="swiperOption" ref="mySwiper">
        <swiper-slide v-for="(item, index) in homeData.slides" :key="index">
          <router-link
            :to="item.href"
            :class="item.cssClass=='centerNoRepeat'?'slide d-none d-sm-block centerNoRepeat':'slide d-none d-sm-block'"
            class="slide d-none d-sm-block"
            :style="{
              background:
                'url('+ url + item.imgPath +')  center center repeat-x',
              height: '450px'
            }"
          ></router-link>
          <router-link
            :to="item.href"
            class="slide d-block d-sm-none"
            :style="{
              background:
                'url('+ url + item.imgPath2 +')  center center repeat-x',
              height: '450px'
            }"
          ></router-link>
        </swiper-slide>
      </swiper>
      <div class="swiper-pagination-banner paginations" slot="pagination"></div>
    </div>
    <b-container fluid>
      <b-row >
        <b-col xs="12" class="mgt20 mgb50">
          <div class="section-thumbs">
            <ul>
              <li class="thumb">
                <router-link to="/sdx-brand">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-38.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.brand_products") }}</span>
                </router-link>
              </li>
              <!--li class="thumb">
                <router-link to="/strategic">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-40.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.strategic_products") }}</span>
                </router-link>
              </!li-->
              <!--li class="thumb">
                <router-link to="/approved">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-50.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.recommended_products") }}</span>
                </router-link>
              </!li-->
              <li class="thumb">
                <router-link to="/product?hl=2">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-29.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.own_products") }}</span>
                </router-link>
              </li>
              <li class="thumb">
                <router-link to="/product?fitout=1">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-47.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.fit_out_products") }}</span>
                </router-link>
              </li>
              <li class="thumb">
                <router-link to="/product?furn=1">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-48.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.Furnishings") }}</span>
                </router-link>
              </li>
              <li class="thumb">
                <router-link to="/product?selectcat=6">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-46.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.decorative") }}</span>
                </router-link>
              </li>
              <li class="thumb">
                <router-link to="/specialist">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-31.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.specialized_companies") }}</span>
                </router-link>
              </li>

              <li class="thumb">
                <router-link to="/guideline?mid=3">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-26.jpg'" />
                  </div>
                  <span>{{ $t("welcome.project_sharing") }}</span>
                </router-link>
              </li>
              <li class="thumb">
                <router-link to="/design-guideline?mid=1">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-20.jpg'" />
                  </div>
                  <span>{{ $t("productfilter.design_styles") }}</span>
                </router-link>
              </li>

              <li class="thumb">
                <router-link to="/design-guideline?mid=20">
                  <div>
                    <img :src="url+ 'misc/tiles/designres-document.jpg'" />
                  </div>
                  <span>{{ $t("designresinfo.standard_project_doc") }}</span>
                </router-link>
              </li>

              <li class="thumb">
                <router-link to="/guideline?mid=6">
                  <div>
                    <img :src="url+ 'misc/tiles/cat-img-43.jpg'" />
                  </div>
                  <span>{{ $t("welcome.learning_resources") }}</span>
                </router-link>
              </li>
              <li class="thumb">

              </li>
              <li class="thumb">

              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="newestProducts.length > 0">
        <b-col xs="12" class="mgt20 mgb50">
          <legend class="pdt20 pdb20 ft26" style="margin-bottom: 5px">
            {{ $t("common.new_products") }}
          </legend>
          <div
            class="new-products"
            style="max-width: 1490px; margin-left: auto; margin-right: auto"
          >
            <swiper
              class="new-product-carousel"
              :options="newPoductswiperOption"
              ref="productSwiper"
            >
              <swiper-slide
                class="product-slide"
                v-for="slide in newestProducts"
                :key="slide.id"
              >
                <div
                  class="product-photo"
                  @click="goPage(slide.id, slide.opt_id)"
                >
                  <div class="product-photo-con">
                    <img
                      class="owl-lazy"
                      :src="homeData.resPrefix + '/' + slide.path + '/s300'"
                      height="105"
                      width="150"
                      :alt="`${slide.bname}-${slide.sname}`"
                      :title="`${slide.bname}-${slide.sname}`"
                    />
                  </div>
                </div>
                <div class="posiR">
                  <h5 class="product-brand" v-if="slide.id != 25">
                    <router-link
                      :to="{
                        path: 'product',
                        query: { selectbrand: slide.brand }
                      }"
                      v-if="slide.bname"
                      >{{ slide.bname }}</router-link
                    >
                    <router-link
                      :to="{
                        path: 'product',
                        query: { selectseries: slide.series1 }
                      }"
                      v-if="slide.series1"
                    >
                      - {{ slide.sname }}</router-link
                    >
                  </h5>
                  <h2>{{ slide.name }}</h2>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination1 paginations" slot="pagination"></div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="browsedProducts.length > 0">
        <b-col xs="12" class="mgt20 mgb50">
          <legend class="pdt20 pdb20 ft26 mgb5">
            {{ $t("common.recently_viewed") }}
          </legend>
          <div
            class="new-products"
            style="max-width: 1490px; margin-left: auto; margin-right: auto"
          >
            <swiper
              class="new-product-carousel"
              :options="productswiperOption"
              ref="productSwiper"
            >
              <swiper-slide
                class="product-slide"
                v-for="(slide, index) in browsedProducts"
                :key="index"
              >
                <div
                  class="product-photo"
                  @click="goPage(slide.id, slide.opt_id)"
                >
                  <div class="product-photo-con">
                    <img
                      class="owl-lazy"
                      :src="homeData.resPrefix + '/' + slide.path + '/s300'"
                      height="105"
                      width="150"
                      :alt="`${slide.bname}-${slide.sname}`"
                      :title="`${slide.bname}-${slide.sname}`"
                    />
                  </div>
                </div>
                <div class="posiR">
                  <h5 class="product-brand" v-if="slide.id != 25">
                    <router-link
                      :to="{
                        path: 'product',
                        query: { selectbrand: slide.brand }
                      }"
                      v-if="slide.bname != ''"
                      >{{ slide.bname }}</router-link
                    >
                    <router-link
                      :to="{
                        path: 'product',
                        query: { selectseries: slide.series1 }
                      }"
                      v-if="slide.series1 != ''"
                    >
                      - {{ slide.sname }}</router-link
                    >
                  </h5>
                  <h2>{{ slide.name }}</h2>
                </div>
              </swiper-slide>
            </swiper>
            <div class="swiper-pagination2 paginations" slot="pagination"></div>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <b-container fluid>
      <b-row>
        <b-col xs="12" class="mgt20">
          <legend class="pdt20 pdb20 ft26 mgb5">
            {{ $t("common.product_cat") }}
          </legend>

          <div class="home-cat">
            <h6>{{ $t("productfilter.Furnishings") }}</h6>
            <ul v-if="catListFurnishing.length > 0">
              <li v-for="cat in catListFurnishing" :key="cat.id">
                <router-link
                  :to="{ path: 'product', query: { selectcat: cat.id } }"
                  >{{ cat.name }}</router-link
                >
              </li>
            </ul>
            <h6>{{ $t("productfilter.fit_out_products") }}</h6>

            <ul v-if="catList.length > 0">
              <li v-for="cat in catList" :key="cat.id">
                <router-link
                  :to="{ path: 'product', query: { selectcat: cat.id } }"
                  >{{ cat.name }}</router-link
                >
              </li>
            </ul>
          </div>
        </b-col>
      </b-row>
    </b-container>
    <footer class="footer">
      <b-container>
        <p class="text-center">
          <img
            src="@/assets/images/logo2.png"
            height="12"
            style="margin-right: 15px"
          />
          &copy; {{ year }} Steve Leung Design Group Limited.
          {{ $t("common.copyright") }}
        </p>
      </b-container>
    </footer>
    <!-- <div v-if="isforRegion">
      <check-lang @dialogChange="dialogChange" />
    </div> -->
    <div v-if="hasReminder">
      <reminder :reminder-list="homeData.reminder" />
    </div>
  </div>
</template>

<script>
import { getYears } from "@/utils/date.js";
import { home, region } from "@/api/home";
import "swiper/dist/css/swiper.css";
import { swiper, swiperSlide } from "vue-awesome-swiper";
import reminder from '@/components/Reminder'
export default {
  components: {
    swiper,
    "swiper-slide": swiperSlide,
    reminder,
  },
  data() {
    return {
      url: process.env.VUE_APP_IMG_URL,
      year: getYears(),
      loading: false,
      swiperImage: [],
      swiperOption: {
        loop: false, //cyclical pattern
        autoplay: true, //autoplay
        effect: "fade", //Effect (fade out)
        speed: 3000,
        pagination: {
          el: ".swiper-pagination-banner",
          clickable: true
        }
      },
      newPoductswiperOption: {
        observer: true,
        slidesPerView: 5,
        slidesPerGroup: 5,
        spaceBetween: 40,
        watchOverflow: true,
        breakpoints: {
          580: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          1000: {
            slidesPerView: 4,
            slidesPerGroup: 4
          },
          1280: {
            slidesPerView: 5,
            slidesPerGroup: 5
          }
        },
        pagination: {
          el: ".swiper-pagination1",
          clickable: true
        }
      },
      productswiperOption: {
        observer: true, //Listening for DOM changes
        slidesPerView: 6, //Sets the number of slides that the Slider container can display simultaneously (Carousel mode)
        slidesPerGroup: 6, //The number of slides defined as a group in Carousel Mode.
        spaceBetween: 40, //Set distance (px) between slides
        watchOverflow: true,
        breakpoints: {
          //Breakpoint Settings, allowing different parameters to be set for different response breakpoints (screen sizes)
          580: {
            slidesPerView: 2,
            slidesPerGroup: 2
          },
          1000: {
            slidesPerView: 4,
            slidesPerGroup: 4
          },
          1280: {
            slidesPerView: 5,
            slidesPerGroup: 5
          },
          1350: {
            slidesPerView: 6,
            slidesPerGroup: 6
          }
        },
        pagination: {
          el: ".swiper-pagination2",
          clickable: true
        }
      },
      newestProducts: [],
      browsedProducts: [],
      catListFurnishing: [],
      catList: [],
      reminderList: [],
      homeData: [],
      isforRegion: false
    };
  },
  created() {
    if (localStorage.getItem("forRegion")) {
      this.isforRegion = false;
      this.CheckforRegion(localStorage.getItem("forRegion"));
    } else {
      this.isforRegion = true;
    }
  },

  computed: {
    hasReminder: function () {
      let list = this.homeData?.reminder ?? [];
      return list.reduce((total, item) => total + item.list.length, 0) > 0;
    },
  },
  methods: {
    CheckforRegion(lang) {
      this.loading = true;
      const data = {
        region: lang
      };
      region(data).then((res) => {
        if (res.status == "success") {
          this.getHome();
        }
      });
    },
    async getHome() {
      // this.loading = true
      let result = await home();
      if (result) {
        this.loading = false;
        this.homeData = result.data;
        this.newestProducts = result.data.newestProducts.data;
        this.browsedProducts = result.data.browsedProducts;
        this.catListFurnishing = result.data.catListFurnishing;
        this.catList = result.data.catList;
        localStorage.setItem("badgeCount", result.data.badgeCount);
        sessionStorage.setItem("badgeCount", result.data.badgeCount);
      }
    },
    goPage(id, opt_id) {
      this.$router.push(`/productdetail?id=${id}&optID=${opt_id}`);
    },
    dialogChange(e) {
      // this.getHome();
      location.reload();
    }
  }
};
</script>

<style lang="scss" scoped>
.slide {
  height: 450px !important;
  background-size: cover !important;
  @media (max-width: 1200px) {
    height: 400px !important;
  }
  @media (max-width: 758px) {
    height: 300px !important;
  }
  @media (max-width: 375px) {
    height: 250px !important;
  }
  &.centerNoRepeat {
    @media (min-width: 1921px) {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      background-color: #000 !important;
    }
  }
}
.paginations {
  position: static;
  margin-top: 20px;
  padding: 0 15px;
  span {
    cursor: pointer;
  }
  @media (max-width: 567px) {
    margin-top: 5px;
    display: flex;
    justify-content: center;
    &.swiper-pagination1, &.swiper-pagination2 {
      margin-top: 20px;
    }
  }
}
/deep/ .swiper-pagination-bullet {
  width: 50px;
  border-radius: 0;
  opacity: 1;
  margin: 0 2px;
  padding: 15px 0;
  background: none;
  @media (max-width: 1024px) {
    width: 40px;
  }
  @media (max-width: 758px) {
    max-width: 30px;
  }
  &::before {
    display: block;
    width: 100%;
    height: 2px;
    background: #d6d6d6;
    content: " ";
  }
  &:hover::before {
    background: #869791;
  }
}
/deep/ .swiper-pagination-bullet-active {
  background: none;
  &::before {
    background: #869791;
  }
}
.section-thumbs {
  /*max-width: 1580px;*/
  max-width: 1530px;
  margin: 1% auto 0;
  @media (max-width: 567px) {
    margin-top: 10px;
  }
  ul {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    li {
      display: inline-block;
      /*width: 22%;
      margin: 2% 1% 1%;*/
      width: 25%;
      margin: 2% 0 1%;
      padding: 0 15px;
      @media (max-width: 1529px) {
        padding: 0 7.5px;
      }
      @media (max-width: 767px) {
        margin-bottom: 15px;
      }
      a {
        /* background: #f2f2f2; */
        padding: 0px;
        display: block;
        color: #555555;
        /* border-bottom: 1px solid #EEE; */
        /* transition: all 0.5s ease; */
        > div {
          position: relative;
          display: flex;
          align-items: center;
          overflow: hidden;
          img {
            width: 100%;
            height: auto;
          }
        }
        span {
          display: block;
          font-size: 18px;
          padding: 15px 0 15px;
          text-align: center;
          letter-spacing: 1px;
          color: #555555;
          @media (max-width: 999px) {
            font-size: 15px;
            letter-spacing: normal;
          }
          @media (max-width: 567px) {
            font-size: 15px;
            padding: 15px 0 8px;
          }
          @media (max-width: 375px) {
            font-size: 13px;
            padding: 10px 0 11px;
          }
        }
      }
      @media (max-width: 767px) {
        width: 50%;
      }
      @media (max-width: 375px) {
      }
    }
  }
}
html[lang="en"] {
  .section-thumbs li a span {
    letter-spacing: normal;
  }
}
.product-slide {
  box-sizing: border-box;
  text-align: center;
  font: bold 18px sans-serif;
  background-color: #ffffff;
  border: none;
  /* max-width: 250px; */
  /* width: 15.2%; */
  width: 95%;
  /* margin: 1% 0; */
  margin: 0;
  padding: 5px;
  vertical-align: middle;
  position: relative;
  .product-photo {
    position: relative;
    display: block;
    text-align: center;
    /* box-shadow: 0 0 10px 5px #f3f3f3 inset; */
    width: 100%;
    margin-bottom: 25px;
    /* padding: 10px 10px; */
    padding: 0px;
    box-sizing: border-box;
    display: flex;
    align-items: center;
    cursor: pointer;
    &::before {
      content: "";
      display: block;
      padding-top: 100%;
    }
    .product-photo-con {
      position: absolute;
      display: flex;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      img {
        display: block;
        max-width: 100%;
        max-height: 100%;
        margin: auto;
        width: auto;
        height: auto;
      }
    }
  }
  .product-brand {
    font-size: 12px;
    text-transform: uppercase;
    margin-top: 12px;
    margin-bottom: 8px;
    max-width: 100%;
    min-height: 13px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    a {
      color: #aaaaaa;
    }
  }
  h2 {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0;
    font-size: 16px;
    max-width: 100%;
  }
}
.home-cat {
  display: flex;
  flex-wrap: wrap;
  max-width: 1330px;
  margin: 0 auto;
  padding: 20px 7.5px 0;
  @media (max-width: 567px) {
    padding-top: 0px;
  }
  h6 {
    font-size: 15px;
    color: #aaaaaa;
    margin: 0px 0px 25px 0px;
    width: 20%;
    text-align: center;
    @media (max-width: 767px) {
      width: 25%;
    }
    @media (max-width: 567px) {
      width: 100%;
    }
  }
  ul {
    width: 80%;
    margin: 0px 0px 35px 0px;
    padding: 0px;
    list-style: none;
    display: flex;
    flex-wrap: wrap;
    @media (max-width: 767px) {
      width: 75%;
    }
    @media (max-width: 567px) {
      width: 100%;
    }
    li {
      /*display: inline-block;*/
      width: 25%;
      text-align: center;
      font-size: 15px;
      margin: 0px 0px 20px 0;
      padding: 0px;
      @media (max-width: 767px) {
        width: 33.33%;
        font-size: 13px;
        line-height: 16px;
        margin: 0px 0px 15px 0;
      }
      @media (max-width: 567px) {
        width: 50%;
      }
      a {
        color: #555555;
      }
    }
  }
}
.footer {
  position: relative !important;
  width: 100%;
  height: auto;
  min-height: 40px;
  font-size: 11px;
  font-weight: normal;
  background-color: #f5f5f5;
  margin-top: 80px;
  display: flex;
  .container {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 15px;
  }
}

/deep/ .el-loading-mask {
  position: fixed;
}
</style>
