import { render, staticRenderFns } from "./specialist.vue?vue&type=template&id=59270146&scoped=true"
import script from "./specialist.vue?vue&type=script&lang=js"
export * from "./specialist.vue?vue&type=script&lang=js"
import style0 from "./specialist.vue?vue&type=style&index=0&id=59270146&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "59270146",
  null
  
)

export default component.exports