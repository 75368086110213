<template>
  <div>
    <div class="brief-list" v-if="Object.values(briefData).length > 0" v-for="(grpData, sName) in briefData" :key="sName">
      <h3 class="list-sub-head">{{ sName }}</h3>
      <ul :class="['flex-center', 'flex-wrap']">
        <li
          class="thumb"
          v-for="(item, index) in grpData"
          :key="index"
        >
          <a :href="item.related_url" target="_blank">
            <div class="thumb-box">
              <div class="thumb-text">{{ item.filename }}</div>
              <div class="thumb-img">
                <img
                  class="img"
                  v-lazy="
                    imgurl + item.path + '/s300'
                  "
                />
              </div>
            </div>

            <div class="item-name">
              <strong><span v-html="item.filename"></span></strong>
            </div>
          </a>

          <div class="specs">
            <div class="tr">
              <div v-html="item.desc"></div>
            </div>

          </div>
        </li>
      </ul>
    </div>
    <div class="no-result" v-else>
      {{ $t("common.no_result") }}
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      imgurl: process.env.VUE_APP_IMG_URL,
      opt_type: []
    };
  },
  props: {
    briefData: {
      type: [Object, Array, Object]
    }
  },
  watch: {

  },

  methods: {
    goPage(id, type, reId) {
      if (type) {
        this.$router.push(`/brief-detail?id=${id}&relateId=${reId}`);
      } else {
        this.$router.push(`/brief-detail?id=${id}`);
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.brief-list {
  margin: 0px 0 60px;
  ul {
    text-align: justify;
    font-size: 0;
    align-items: flex-start !important;
    vertical-align: top;
    li {
      font-size: 14px;
      background-color: #ffffff;
      margin: 0px 0px 50px;
      padding: 15px 15px 35px;
      width: 20%;
      text-align: left;
      vertical-align: top;
      position: relative;
      display: inline-block;
      cursor: pointer;
      @media (max-width: 1559px) {
        padding: 7.5px;
      }
      @media (max-width: 1023px) {
        width: 33.33%;
      }
      @media (max-width: 767px) {
        width: 50%;
        margin: 0 0 30px;
      }
      .thumb-box {
        position: relative;
        &::before {
          content: "";
          display: block;
          padding-top: 100%;
          overflow: hidden;
          background: #ffffff;
        }

        .thumb-text {
          position: absolute;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          vertical-align: middle;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .thumb-img {
          position: absolute;
          display: flex;
          top: 0;
          left: 0;
          bottom: 0;
          right: 0;
          img {
            display: block;
            min-width: 100%;
            // min-height: 100%;
            max-width: 100%;
            max-height: 100%;
            width: auto;
            height: auto;
            margin: auto 0;
            object-fit: cover;
            transition: transform 1s;
            transition-timing-function: ease;
          }
        }
      }
      .item-name {
        min-height: 26px;
        line-height: 19px;
        margin-top: 1px;
        font-size: 16px;
      }
      .specs {
        font-size: 12px;
        color: #999999;
        display: table;
        margin-top: 5px;
        .tr {
          font-size: 12px;
          font-weight: normal;
          color: #999999;
          display: table-row;
          text-align: left;
          > div {
            font-size: 12px;
            color: #999999;
            display: table-cell;
            padding-bottom: 1px;
            text-align: left;
            &:first-child {
              padding-right: 5px;
              min-width: 70px;
            }
          }
        }
      }
    }
  }
}
.no-result {
  text-align: center;
  margin-bottom: 80px;
  color: #999;
}
.codes {
  color: #555555 !important;
  cursor: pointer;
}
.codes span:hover {
  color: #950c0f;
}
</style>
