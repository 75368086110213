export default {
  common: {
    login: 'Login',
    email:'E-Mail address',
    password:'Password',
    system:'Design Resource System',
    forgetPassword:'Forgot Password?',
    rember:'Remember Me',
	  adminCenter:'Admin Center',
    Load: 'Loading',
    loadmore: 'Load More',
    loadover:'(All data has been loaded)',
    headsearch:'Search',
    userreview:'My Review',
    uservote:'My Vote',
    changepassword:'Change Password',
    loginout:'Logout',
    language:'Language',
    noData:'No matching results!',
    result_none: "No result",
    inform: "Notices to Vendors",
    wishlist: "Wish List",
    metaname:"Design Resource System",
    lib_file: "File Download",
    all_cat: "All Categories",
    prodCategory:'All Product Categories',
    wishCategory:'All Wish Categories',
    sent: "Sent",
    draft: "Draft",
    details: "Details",
    confirm_to_del: "Confirm delete?",
    confirm: "Confirm",
    cancel: "Cancel",
    copyright: "All rights reserved",
    action: "Send Reset Link",
    message: "Back to login page",
    news: "Updates",
    year:"year",
    search_results: "Search Results",
    no_result: "No Matched Result",
    product:'Materials Products',
    my_review: "My Review",
    to_be_rated: "To be Rated",
    rated: "Rated",
    ratings_success: "Comments written successfully!",
    ratings_mandatory: "The relevant field is required.",
    ratings_mandatory_rating:"The rating field is required.",
    supplier_rating: "Supplier Rating",
    rate: "Rate",
    select_region: "Please Select Applicable Location / Project Location",
    forHK: "Hong Kong",
    forCN: "Mainland China",
    forOS: "Overseas",
    resetPassword:'Reset password',
    new_products: "New Products",
    recently_viewed: "Recently Viewed",
    product_cat: "Product Category",
    vote: "Vote",
    reminder: "Reminder",
    sortOption:[
      {
        value:'',
        text:'Order By'
      },{
        value:'asc',
        text:'Ascending'
      },{
        value:'desc',
        text:'Descending'
      },
    ],

  },
  login: {
    msg: {
      error: "Login Error",
      success: "Login Success!",
      fail: "Email/Password Incorrect!",
      inactive: "Email has been discontinued!",
      expired: "Email has expired!",
      use_chrome: "Please use Chrome Browser to login",
      no_mobile_no: "The system can't find your phone number",
      invalid_user: "The email or password is incorrect",
      no_input: "Please enter your email address and password",
      no_input_veri: "Please enter all fields",
      no_external_login_right: "No external login authorization",
      invalid_code_or_expired: "Verification code is incorrect or has expired",
      invalid_login_code: "Login Verification code is incorrect",
      failed_send_vericode: "The verification code could not be issued temporarily, please try again later",
      failed_check_vericode: "The system is temporarily unable to verify, please try again later",
      too_many_attempts: "Too many attempts, please try again later",
    }
  },
  navData:[
    {
      // url:'/promotions',
      url:'/',
      // text:'Featured Promotions',
      children:[
        /* {
          url:'/promotions/1',
          text:`Steve Leung's Private Collection`,
        },{
          url:'/promotions/2',
          text:'Marco Szeto Artwork',
        },{
          url:'/privateresidence',
          text:'Private Residence Design Section',
        } */
      ]
    },{
      // url:'/strategic',
      url:'/',
      // text:'Strategic',
      children:[
       /*  {
          url:'/strategic/brand?type=brands',
          text:'Brands',
        },{
          url:"/strategic/brand?type=distributors",
          text:'Distributors',
        } */
      ]
    },{
      // url:'/approved',
      // url:'/',
      // text:'Approved Brands',
      url:'https://ai.steveleung.com',
      text:'SLD AI',
      children:[
        /* {
          url:"/approved/brand?type=brands",
          text:'Brands',
        },{
          url:"/approved/brand?type=distributors&loc=CN",
          text:'China Distributors',
        },{
          url:"/approved/brand?type=distributors&loc=HK",
          text:'Hong Kong Distributors',
        } */
      ]
    },{
      url:'/sdx-brand',
      text:'SLD x Brands',
      children:[]
    },{
      url:'/product',
      text:'Materials/Products',
      children:[
        {
          text:'In-House Design Products',
          childnode:[
            {
              url:`/product?hl=2&selectcat=2`,
              catId:2,
              text:'Loose Furniture'
            },{
              url:`/product?hl=2&selectcat=3`,
              catId:3,
              text:'Decorative Lightings'
            },{
              url:`/product?hl=2&selectcat=4&selectsubcat=229`,
              text:'Rugs'
            },{
              url:`/product?hl=2&selectcat=393`,
              text:'Artworks'
            },{
              url:'',
              text:'Decorative Accessories'
            }
          ]
        },{
          text:'Furnishings',
          childnode:[
            {
              url:`/product?selectcat=2`,
              text:'Loose Furniture'
            },{
              url:`/product?selectcat=417`,
              catId:417,
              text:'Outdoor Furniture'
            },{
              url:`/product?selectcat=3`,
              text:'Decorative Lightings'
            },
            {
              url:'',
              text:'Curtains'
            },{
              url:`/product?selectcat=4&selectsubcat=229`,
              text:'Rugs'
            },{
              url:`/product?selectcat=393`,
              text:'Artworks'
            },{
              url:`/product?selectcat=5`,
              text:'Soft Furnishings'
            },{
              url:`/product?selectcat=6`,
              text:'Decorative Accessories'
            },{
              url:`/product?selectcat=362`,
              text:'Upholstery'
            }
          ]
        },{
          text:'Fit-Out Products',
          childnode:[
            {
              url:`/product?selectcat=319`,
              text:'Stones'
            },{
              url:`/product?selectcat=315`,
              text:'Tiles & Mosaics'
            },{
              url:`/product?selectcat=304`,
              text:'Floorings'
            },{
              url:`/product?selectcat=4`,
              text:'Carpets'
            },{
              url:`/product?selectcat=298`,
              text:'Wall Panels'
            },{
              url:`/product?selectcat=151`,
              text:'Wall Coverings'
            },{
              url:`/product?selectcat=384`,
              text:'Special Finishes'
            },{
              url:`/product?selectcat=367`,
              text:'Paints'
            },{
              url:`/product?selectcat=322`,
              text:'Doors'
            },{
              url:`/product?selectcat=73`,
              text:'Ironmongery'
            },{
              url:`/product?selectcat=355`,
              text:'Glasses & Mirrors'
            },{
              url:`/product?selectcat=362`,
              text:'Upholstery'
            },{
              url:`/product?selectcat=161`,
              text:'Veneers'
            },{
              url:`/product?selectcat=158`,
              text:'Metal Finishes'
            },{
              url:`/product?selectcat=359`,
              text:'Laminates'
            },{
              url:`/product?selectcat=376`,
              text:'Fixed Furniture'
            },{
              url:`/product?selectcat=68`,
              text:'Sockets & Switches'
            },{
              url:`/product?selectcat=69`,
              text:'Architectural Lightings'
            },{
              url:`/product?selectcat=342`,
              text:'Window Treatments'
            },{
              url:'',
              text:'Curtains'
            },{
              url:`/product?selectcat=299`,
              text:'Healthcare Products'
            }
          ]
        },{
          text:'Sanitary Products',
          childnode:[
            {
              url:`/product?selectcat=311&selectsubcat=66`,
              text:'Sanitary Wares'
            },{
              url:`/product?selectcat=311&selectsubcat=72`,
              text:'Sanitary Fittings'
            },{
              url:`/product?selectcat=311&selectsubcat=56`,
              text:'Bathroom Accessories'
            },{
              url:`/product?selectcat=311&selectsubcat=324`,
              text:'Shower Enclosures'
            },{
              url:`/product?selectcat=311&selectsubcat=402`,
              text:'Toilet Cubicles'
            }
          ]
        },{
          text:'Kitchen Products',
          childnode:[
            {
              url:`/product?selectcat=306&selectsubcat=307`,
              text:'Kitchen Sinks'
            },{
              url:`/product?selectcat=306&selectsubcat=308`,
              text:'Kitchen Mixers'
            },{
              url:`/product?selectcat=306&selectsubcat=309`,
              text:'Kitchen Accessories'
            },{
              url:`/product?selectcat=306&selectsubcat=310`,
              text:'Kitchen Cabinetry'
            }
          ]
        },{
          text:'Appliances/Equipment',
          childnode:[
            {
              url:'/product?selectcat=263',
              text:'Smart Home Systems'
            },{
              url:'/product?selectcat=306&selectsubcat=316',
              text:'Kitchen Appliances'
            },{
              url:'/product?selectcat=329',
              text:'Laundry Room Appliances'
            },{
              url:'/product?selectcat=285',
              text:'Gym Equipment'
            },{
              url:'/product?selectcat=283',
              text:'Security Equipment'
            },{
              url:'/product?selectcat=325',
              text:'Fireplaces'
            },{
              url:'/product?selectcat=369',
              text:'Home Theatre Systems'
            },{
              url:'/product?selectcat=295',
              text:'Professional Contractor Systems'
            }
          ]
        }
      ]
    },{
      url:'/specialist',
      text:'Suppliers',
      children:[
        {
          text:'FF&E Suppliers',
          childnode:[
            {
              url:'/supplier?id=1&name=FF&E Suppliers',
              text:'Proprietary Product Suppliers'
            },{
              url:'/supplier?id=2&name=FF&E Suppliers',
              text:'Custom-made Product Suppliers'
            },{
              url:'/supplier-cats?catId=3&name=FF&E Suppliers',
              text:'Third Party Service Providers'
            },
          ]
        },{
          text:'Production Companies',
          childnode:[
            {
              url:'/supplier-companylist?cl=3&ct=14',
              text:'Renderings'
            },{
              url:'/supplier-companylist?cl=3&ct=15',
              text:'Design Development Drawings'
            },{
              url:'/supplier-companylist?cl=3&ct=16',
              text:'Construction Drawings'
            },{
              url:'/supplier-companylist?cl=3&ct=17',
              text:'MEP Construction Drawings'
            },{
              url:'/supplier-companylist?cl=3&ct=18',
              text:'Virtual Exhibition'
            },{
              url:'/supplier-companylist?cl=3&ct=19',
              text:'BIM'
            },{
              url:'/supplier-companylist?cl=3&ct=20',
              text:'Second Rank Photo Shootings'
            },{
              url:'/supplier-companylist?cl=3&ct=37',
              text:'Professional Photographers'
            },{
              url:'/supplier-companylist?cl=3&ct=36',
              text:'Drafting and Printing'
            }
          ]
        },{
          text:'Professional Consultants',
          childnode:[
            {
              url:'/supplier-companylist?cl=2&ct=42',
              text:'Architecture'
            },
            // {
            //   url:'',
            //   text:'Structural Engineering'
            // },
            {
              url:'/supplier-companylist?cl=2&ct=2',
              text:'Lighting'
            },/* {
              url:'/supplier-companylist?cl=2&ct=52',
              text:'Second Rank Lighting'
            }, */{
              url:'/supplier-companylist?cl=2&ct=3',
              text:'Mechanical, Electrical & Plumbing'
            },{
              url:'/supplier-companylist?cl=2&ct=5',
              text:'Quantity Surveying'
            },{
              url:'/supplier-companylist?cl=2&ct=4',
              text:'Landscape'
            },{
              url:'/supplier-companylist?cl=2&ct=6',
              text:'Signage'
            },{
              url:'/supplier-companylist?cl=2&ct=10',
              text:'Acoustics'
            },
            // {
            //   url:'',
            //   text:'Gas Systems'
            // },
            {
              url:'/supplier-companylist?cl=2&ct=7',
              text:'Sustainability'
            },{
              url:'/supplier-companylist?cl=2&ct=8',
              text:'Security'
            },{
              url:'/supplier-companylist?cl=2&ct=44',
              text:'Information Technology/Audio Visual'
            },{
              url:'/supplier-companylist?cl=2&ct=45',
              text:'Parking Space Planning'
            },{
              url:'/supplier-companylist?cl=2&ct=49',
              text:'VR/AR Interaction'
            },{
              url:'/supplier-companylist?cl=2&ct=53',
              text:'Graphic Design'
            }
          ]
        },{
          text:'Professional Equipment & System Suppliers',
          childnode:[
            {
              url:'',
              text:'Security Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=22',
              text:'Smart Home Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=23',
              text:'Security Doors'
            },{
              url:'',
              text:'Swimming Pool Filtration Systems'
            },{
              url:'',
              text:'Spa Equipment'
            },{
              url:'/supplier-companylist?cl=1&ct=24',
              text:'Wine Cellar Equipment'
            },{
              url:'/supplier-companylist?cl=1&ct=25',
              text:'Water Filtration Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=26',
              text:'Audio Visual Systems'
            },{
              url:'',
              text:'IT Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=27',
              text:'Air Filtration Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=28',
              text:'Professional Kitchen Equipment'
            },{
              url:'/supplier-companylist?cl=1&ct=35',
              text:'Gym Equipment'
            },{
              url:'/supplier-companylist?cl=1&ct=29',
              text:'Wellness Design Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=31',
              text:'Underfloor Heating Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=46',
              text:'Digital Workspace Solutions'
            },{
              url:'/supplier-companylist?cl=1&ct=47',
              text:'Smart Hospitality Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=48',
              text:'Strong Room'
            },{
              url:'/supplier-companylist?cl=1&ct=51',
              text:'Exhibition Systems'
            },{
              url:'/supplier-companylist?cl=1&ct=54',
              text:'Optical Energy Storage / Green Electricity'
            }
          ]
        }/* ,{
          text:'Contractors',
          childnode:[
            {
              url:'/supplier-companylist?cl=5&ct=43',
              text:'Contractors'
            },
          ]
        } */
      ]
    },{
      url:'/guideline?mid=3',
      text:'Project Sharings',
      children:[
        {
          url:'/deliverable',
          text:'Design Deliverables'
        },{
          url:'/guideline?mid=20',
          text:'Project Photos'
        },{
          url:'/design-guideline?mid=8',
          text:'Design Guidelines'
        }
      ]
    },{
      url:'/design-guideline?mid=1',
      text:'Design Styles',
      children:[
        {
          url:'/project-file?mid=1&id=801',
          text:'Latest Design Styles',
          role_needed: '66'
        },{
          url:'/project-file?mid=1&id=1037',
          text:'2023 Design Styles'
        },{
          url:'/project-file?mid=1&id=1015',
          text:'2022 Design Styles'
        },{
          url:'/project-file?mid=1&id=757',
          text:'2021 Design Styles'
        },{
          url:'/project-file?mid=1&id=4',
          text:'2020 Design Styles'
        },{
          url:'/project-file?mid=1&id=69',
          text:'2019 Design Styles'
        },{
          url:'/project-file?mid=1&id=5',
          text:'2018 Design Styles'
        },{
          url:'/project-file?mid=1&id=3',
          text:'2017 Design Styles'
        },{
          url:'/project-file?mid=1&id=2',
          text:'2016 Design Styles'
        },{
          url:'/project-file?mid=1&id=1',
          text:'2015 Design Styles'
        }
      ]
    },{
      url:'/design-guideline?mid=20',
      text:'Standard Project Documents',
      children:[
        {
          url:'/project-file?mid=20&id=58',
          text:'SLD Specification Templates'
        },{
          url:'/project-file?mid=20&id=56',
          text:'SLD Presentation Formats'
        },{
          url:'/project-file?mid=20&id=977',
          text:'SL2.0 Specification Templates'
        },{
          url:'/project-file?mid=20&id=930',
          text:'SL2.0 Presentation Formats'
        },{
          url:'/project-file?mid=20&id=709',
          text:'SLL Specification Templates'
        },{
          url:'/project-file?mid=20&id=929',
          text:'SLL Presentation Formats'
        },{
          url:'/project-file?mid=20&id=727',
          text:'SLL List Templates'
        },{
          url:'/project-file?mid=20&id=59',
          text:'General Specifications'
        },{
          url:'/project-file?mid=20&id=57',
          text:'Drawing Guidelines'
        },{
          url:'/project-file?mid=20&id=60',
          text:'Drawing Register Templates'
        },{
          url:'/project-file?mid=20&id=61',
          text:'Drawing Issue Sheet Templates'
        },{
          url:'/project-file?mid=20&id=708',
          text:'Material/Product Utilization Form Templates'
        },{
          url:'/project-file?mid=20&id=62',
          text:'Sample Board Templates'
        }
      ]
    },{
      url:'/guideline?mid=6',
      text:'Learning Resources',
      children:[
        {
          url:'/design-guideline?mid=30',
          text:'SLD Talk'
        },{
          url:'/design-guideline?mid=6',
          text:'Design References'
        },{
          url:'/design-guideline?mid=49',
          text:'AI Sharings'
        },{
          url:'/design-guideline?mid=7',
          text:'Public Speeches'
        },{
          url:'/design-guideline?mid=31',
          text:'Brand Products Introduction Videos'
        },/* {
          url:'/design-guideline?mid=33',
          text:'Exhibition Sharings'
        },{
          url:'/design-guideline?mid=3',
          text:'Technical Reference'
        },{
          url:'/design-guideline?mid=22',
          text:'Wellness Design'
        },{
          url:'/project-file?mid=35&id=66',
          text:'Design Considerations'
        },{
          url:'/design-guideline?mid=23',
          text:'Material/Product Guidelines'
        },{
          url:'/design-guideline?mid=36',
          text:'Standard Provision Design'
        }, */{
          url:'/design-guideline?mid=37',
          text:'Presentation Skill Sharing Session'
        },/* {
          url:'/design-guideline?mid=39',
          text:'Sales Training Session'
        }, */{
          url:'/design-guideline?mid=40',
          text:'Internal Training Session'
        },/* {
          url:'/design-guideline?mid=41',
          text:'SLD Annual Awards Ceremony'
        },{
        //   url:'/design-guideline?mid=42',
        //   text:'Orientation'
        // },{
          url:'/design-guideline?mid=43',
          text:'Central Management Meeting'
        }, {
          url:'/design-guideline?mid=45',
          text:'Interior Staging'
        },{
          url:'/design-guideline?mid=46',
          text:'Magazines'
        },{
          url:'/design-guideline?mid=47',
          text:'Artwork Sharings'
        }*/
      ]
    }
  ],
  productfilter:{
    product_tbc:"Inquiry(Negotiated separately)",
    products: "Products",
    Highlight:'Highlight',
    Category:'Category',
    FitOut:'Fit-Out Products',
    Furnishings:'Furnishings',
    filter_subcat: "Subcategory",
    Brand:'Brand',
    Series:'Series',
    Color:'Color',
    MainMaterials:'MainMaterials',
    Library:'Design Styles',
    retailprice:'Ref. Retail Price',
    costprice:'Ref. Cost',
    Size:'Size',
    Leadtime:'Lead Time',
    Day:'Day(s)',
    Lastupdate:'Last Update',
    Produtype:'SLD x Brand Products',
    inc_ship_cost: "Inc. In Cost",
    inc_vat: "Inc. In Vat",
    contactinfo:'Contact Information',
    contact_project_info: "Project Information",
    contact_product_info: "Product Information",
    contact_wechat: "Wechat Number",
    contact_request: "Request",
    contact_mobile: "Mobile Number",
    Vendor:'Contact Manufacturer/Vendor',
    Contactperson:'Contact Person',
    Telephone:'Telephone',
    Email:'Email',
    projectNum:'Select Project Number',
    Attachment:'Attachment',
    Toemail:'CC Email to',
    Myphone:'My Mobile Number',
    Wechat:'My Wechat',
    time:'Reply/Report Date',
    Addequiry:'Add to Enquiry List',
    Send:'Send',
    Cancel:'Cancel',
    project1:'Include Project Name',
    project2:'Include Client Name',
    project3:'Provide Sample',
    project4:'Provide Proposal',
    msg:'List the products to be inquired  e.g. Page 039: LW47245B',
    checked:'Select All',
    checkednum:'Selected',
    Region:'Applicable Region',
    orderby:'Order By',
    pageShow:'Page Shows',
    regionption:[
      {
        value:'HK',
        text:'Hong Kong'
      },{
        value:'CN',
        text:'Mainland China'
      },{
        value:'OS',
        text:'Overseas'
      }
    ],
    pictureShows:'Show by',
    pictureOptions:[{
        value:null,
        text:'Preset'
      },
      {
        value:1,
        text:'Product Catalogue'
      },{
        value:0,
        text:'Product'
      }],
    sortSelectOptions: [
      {
        text:'Preset',
        val:'default'
      },{
        text:'Latest',
        val:'newest'
      },{
        text:'Price From Low To High',
        val:'priceasc'
      },{
        text:'Price From High To Low',
        val:'pricedesc'
      },{
        text:'Page View From High To Low',
        val:'pageview'
      },{
        text:'Wish List From High To Low',
        val:'wishlist'
      }

    ],
    unit:'items',
    resultxt:'search results for',
    moreDatail:'More Details...',
    description:'Description/Specification',
    brand_products: "SLD x Brand Products",
    new_brand_products: "Latest SLD x Brand Products",
    own_products: "In-house Design Products",
    strategic_products: "Strategic Brand Products",
    recommended_products: "Approved Brand Products",
    registered_products: "Registered Brand Products",
    strategic_brands: "Strategic Brand",
    contact_alert_select_project: "Please select a project!",
    recommended_brands: "Approved Brand",
    registered_brands: "Registered Brand",
    remark: "Remark",
    fit_out_products: "Fit-Out Products",
    decorative: "Decorative Accessories",
    specialized_companies: "Suppliers",
    design_styles: "Design Styles",
    Confirm:'Confirm',
    NotCategory:'Not selecting category',
    CreateCategory:'Create category',
    DefaultCategory:'Default category',
    CategoryName:'Name',
    AddBtn:'Add',
    UpdateBtn:'Modify',
    UpdateName:'Modify category',
    DelCategory:'Delete category',
    ConfirmDel:'Whether to delete all wish list under the category',
    mobileFilter:' Materials/Products Filter',
  },
  programOptions: [
    {
      type: 1,
      text: "Include Project Name"
    },
    {
      type: 2,
      text: "Include Client Name"
    },
    {
      type: 3,
      text: "Provide Sample"
    },
    {
      type: 4,
      text: "Provide Proposal"
    }
  ],
  send:{
    msg:''
  },
  supplierfilter:{
    supplietype1:'FF&E Suppliers',
    supplietype2:'Production Companies',
    supplietype3:'Professional Consultants',
    supplietype4:'Professional Equipment & System Suppliers',
    supplietype5:'Contractors',
    Suppliers:'Suppliers',
    product_brand:'Product Brands',
    nodata:'no result',
    pricerenders:'Price Summary of Renderers ',
    pricerendersPRC:'2024 Renderer Price Summary (PRC)',
    pricerendersThai:'2024 Renderer Price Summary (Thai)',
    reviewsrenders:'Users Review Summary of Renderers ',
    designdraffers:'Price Summary of Design Development Drafters ',
    reviewdesigndraffers:'Users Review Summary of Design Development Drafters ',
    rating:'Ratings',
    userreview:'User Reviews',
    score:'Score',
    year:'YEAR',
    min:'Min',
    avg:'Avg',
    max:'max',
    isnew:'NEW',
    contact_lang:'Contact Language',
    company_size:'Company Size',
    price_rate:'Price Rate',
    company_intro:'Company Intro',
    recommend_by:'Recommended By',
    ware_date:'Warehousing Date',
    sld_project:'20 Recent SLDG Projects',
    num_project_pre:'',
    num_project_beh:'Recent SLDG Projects',
    writeBtn:'Write a Review',
    rating_target:'Rating Target',
    rating_target_type:'Rating Target Type',
    supplier_rating:'Supplier Rating',
    rating_project:'Project',
    rating_review:'Review',
    rating_rate:'Rate',
    optional:'optional',
    upload_attartch:'Upload Attachment',
    submit:'Submit',
    related_document:'Related Documents',
    please_select: 'Please Select',
    upload_drag: "Drag and drop files are uploaded here",
    upload_width: "The width of the lower limit",
    upload_height: "The height of the lower limit",
    upload_limit: "Only JPG and PNG images are supported！",
    upload_size:"The size of the uploaded picture cannot exceed 2MB！",

  },
  Promotions:{
    promotions: "Featured Promotions",
    steve: "Steve Leung's Private Collection",
    marco: "Marco Szeto Artwork",
    type_private_house: "Private Residence Design Section",
    production_companies: "Production Companies",
    equip_sys_suppliers: "Professional Equipment & System Suppliers",
  },
  sdxBrand:{
    san_products: "Sanitary Products",
    contact_vendor: "Contact Manufacturer/Vendor",
    download_product_cat: "Download Product Catalogue",
    contact_vendors: "Contact Vendor",
    direct_distributor:'Direct/Distributor',
    store_visiting:'Appointment for Store Visiting',
    furniture: "Loose Furniture",
    lighting: "Decorative Lightings",
    see_all: "view all",
    discontinued: "Discontinued",
  },
  Strateg:{
    strategic: "Strategic",
    brands: "Brands",
    distributors: "Distributors",
    product_cat: [
      {
        name:"Product Category",
        id:null
      }
    ],
    header_company_website: "Company Website",
    ta_erp: "Creative Home Furniture ERP link",
    ta_erp_doc: "ERP User Manual",
    header_online_store: "Online Store",
    header_product_catalogue: "Product Catalogue",
    projectType1: [
      {
        type: 1,
        text: "Include Project Name"
      },
      {
        type: 2,
        text: "Include Client Name"
      }

    ],
    projectType2: [
      {
        type: 3,
        text: "Provide Sample"
      },
      {
        type: 4,
        text: "Provide Proposal"
      }
    ],
    contact_preview: "Preview",
    approved_china_distributors: "Approved China Distributors",
    approved_hk_distributors: "Approved Hong Kong Distributors",
    strategic_distributors: "Strategic Distributors",
    strategic_brands: "Strategic Brand",

  },
  approved:{
    approved: "Approved Brands",
    brands: "Brands",
    china_distributors: "China Distributors",
    hk_distributors: "Hong Kong Distributors",
    approved_brands: "Approved Brands",
  },
  welcome:{
    project_sharing: "Project Sharings",
    design_sharing:'Design Sharings',
    speech_guidance: "Speeches/Guidances",
    learning_resources: "Learning Resources",
    project_photos: "Project Photos",
    news: "Updates",
    sortby: "Order By",
    sort_desc_update: "Descending by Update Time",
    sort_asc_update: "Ascending by Update Time",
    added_success: "Success!",
    added_fail: "Fail!",
    added_to_cart: "Product added.",
    send_email:'Sending mail',
    send_email_fail:'Sending mail Fail!',
    design_deliverable: "Design Deliverables",
    design_guidelines: "Design Guidelines",
    project_code: "Project Number",
    project_location: "Project Location",
    project_client: "Name of Client",
    project_cost_fitting_out: "Final Cost for Fit-out",
    project_cost_ffne: "Final Cost for FF&E",
    project_rmb_per_sqm: "sqm",
    project_scope: "Scope of Works of Implementation",
    project_type: "Project Category",
    project_photo_shooting_pro: "Professional Photo Shooting",
    project_photo_shooting_second: "Second Rank Photo Shooting",
    project_photo_shooting_internal: "Internal Photo Shooting",
    guildline: "Guildline",
    design_style:"Design Style",
    project_grade_general: "General Project",
    project_grade_important: "Important Project (#)",
    project_grade_quality: "Quality Project (*)",
    project_grade_premium: "Premium Project (**)",
    project_grade: "Project Ranking",
    project_contractor: "Contractor",
    project_design_scope: "Scope of Design",
    project_design_area: "Area",
    project_requirements: "Design Requirements",
    project_team_construct: "Architectural Design Team",
    project_team_interior: "Interior Design Team",
    project_team_four_cat: "Design Team for Four Categories of FF&E Items",
    project_team_ffne: "Design Team for Accessories",
    project_team_implement: "FF&E Implementation Team",
    project_budget_construct: "Budget for Construction",
    project_cost_construct: "Final Cost for Construction",
    project_budget_fitting_out: "Budget for Fit-out",
    project_equip_excluded: "Equipment Excl.",
    project_budget_ffne: "Budget for FF&E",
    project_new_style: "Application of New Design Styles",
    project_photo_shooting: "Photo Shooting",
    file_preview_download: "File Preview/Download",
    file_no_external: "Only visible to the company's internal network",
    select_all: "Select All",
    download: "Download",
    album_statement: "The photos in this folder can only be used for reference purposes. Do not plagiarize or use in presentation.",
    related_url: "Related Products/Info. Link",
    view_count: "Views",
    pdf_error:'The network is abnormal, and the file fails to be obtained'
  },
  designresinfo:{
    speaker: "Speaker",
    date: "Time",
    place: "Place",
    event_name: "Event Name",
    event_org: "Organizer",
    theme: "Theme",
    people: "People",
    audience: "Audience",
    brief: "Brief",
    standard_project_doc: "Standard Project Documents",
    rate: "Rate",
    no_record: "No record",
    whoview: "List of Viewers",
    viewname: "Viewer Name",
    viewcount: "Times Viewd",
    lastview: "Last Viewed",
    confirm: "Confirm",
  },
  vote:{
    vote: "Vote",
    vote_intro: "Introduction to voting",
    vote_rule: "Voting rules",
    view: "View",
    to_be_voted: "To be Voted",
    voted: "Voted",
    is_voting: "Voting",
    clear: "Clear",
    submit: "Submit",
    vote_ended: "Voting ends",
    vote_success: "The vote was successful",
    vote_fail: "Vote failed",
  }

}
