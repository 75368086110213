
<template>
  <div class="content">
    <b-container fluid >
      <b-row  class="drs">
        <b-col cols="12">
          <h5 class="name mgb20 tc">{{$t('supplierfilter.Suppliers')}}
          </h5>
        </b-col>
        <b-col cols="12">
          <ul class="drs-list ">
            <li class="thumb">
							<router-link :to="{path:'/supplier-cats?name=' + encodeURIComponent($t('supplierfilter.supplietype1')) }">
								<div class="img-box flex">
									<img :src="url + 'misc/tiles/registered-suppliers.jpg'">
								</div>
								<span>{{$t('supplierfilter.supplietype1')}}</span>
							</router-link>
						</li>
            <li class="thumb">
							<router-link to="/supplier-company?cl=3">
								<div class="img-box flex">
									<img :src="url + 'misc/tiles/cat-img-44.jpg'">
								</div>
								<span>{{$t('supplierfilter.supplietype2')}}</span>
							</router-link>
						</li>
            <li class="thumb">
							<router-link to="/supplier-company?cl=2">
								<div class="img-box flex">
									<img :src="url + 'misc/tiles/specialist-consultants.jpg'">
								</div>
								<span>{{$t('supplierfilter.supplietype3')}}</span>
							</router-link>
						</li>
            <li class="thumb">
							<router-link to="/supplier-company?cl=1">
								<div class="img-box flex">
									<img :src="url + 'misc/tiles/specialist-suppliers.jpg'">
								</div>
								<span>{{$t('supplierfilter.supplietype4')}}</span>
							</router-link>
						</li>
            <!--li class="thumb">
							<router-link to="/supplier-companylist?cl=5&ct=43">
								<div class="img-box flex">
									<img :src="url + 'misc/tiles/specialist-contractors.jpg'">
								</div>
								<span>{{$t('supplierfilter.supplietype5')}}</span>
							</router-link>
						</li-->

          </ul>
        </b-col>

      </b-row>
    </b-container>
  </div>
</template>

<script>
import { getProductcat } from "@/api/supply";
export default {
  data(){
    return{
      catid:null,
      catName:'',
      resultList:[],
      url: process.env.VUE_APP_IMG_URL
    }
  },
  created(){
    this.catid =this.$route.query.id ? this.$route.query.id:null,
    this.catName = this.$route.query.name ? this.$route.query.name : '';
    this.getSupplierCats();

  },
  methods:{
    getSupplierCats(){
      getProductcat(this.catid).then(res=>{
        this.resultList = res.data;
      })
    },
    goPage(catid,id){
      this.$router.push(`/supplier-cats?catId=${catid}&id=${id}`)
    }


  }
}
</script>

<style lang="scss" scoped>
.content{
  padding: 25px 0 40px;
  overflow-x: hidden;
  .name{
    padding: 20px 0px 20px;
  }
  .drs{
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    .col-12 {
      padding: 0;
    }
    @media (max-width: 1559px) {
      .drs-list {
        text-align: justify;
        .thumb {
          padding: 7.5px;
        }
      }
    }

    @media (max-width: 1023px) {
      /*margin: 0 -15px;*/
      .drs-list {
        .thumb {
          width: 33.33%;
          span{
            padding: 15px 0 8px;
          }
        }
      }
    }
    @media (max-width: 767px) {
      /*margin: 0 -15px;*/
      .drs-list {
        width: auto;
        margin-bottom: 50px;
        .thumb {
          margin: 0 0 20px 0;
        }
      }

    }
    @media (max-width: 567px) {
      .drs-list{
        .thumb {
          width: 50%;
          font-size: 13px;
          span{
            font-size: 15px;
          }
        }
      }

    }
    @media (max-width: 375px) {
      .drs-list{
          .thumb {
          span{
            font-size: 13px;
          }
        }
      }

    }
  }
  .reders{
    max-width: 1530px;
    margin: 0 auto;
    text-align: left;
    @media (max-width: 1023px) {
      margin: 0 -15px;
      text-align: center;
    }
  }

}
.drs-list{

  margin: 0 auto;
  /* max-width: 1100px; */
  width: 100%;
  padding: 0;
  text-align: center;
  font-size: 0;
  margin-bottom: 50px;
  .thumb{
    z-index: 0;
    display: inline-block;
    width: 20%;
    text-align: left;
    font-size: 15px;
    margin: 0px 0px 1% 0;
    padding: 15px;
    vertical-align: top;
    .img-box{
      position: relative;
      overflow: hidden;
      img{
        width: 100%;
        height: auto;
        transition: transform 1s;
        transition-timing-function: ease;
      }
    }
    span{
      display: block;
      font-size: 18px;
      padding: 20px 0 10px;
      text-align: center;
      letter-spacing: 1px;
      color: #555555;
      position: relative;
    }
    &:hover{
      img{
        transform: scale(1.1);
      }
    }
  }
}
html[lang="en"] .drs-list .thumb span{    letter-spacing: normal;}
</style>
